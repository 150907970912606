import { memo } from "react";

import Grid from "@mui/material/Grid";
import { styled, Theme } from "@mui/material/styles";

export const HeaderHeight = 64;

const PREFIX = "HeaderHeight";

export const classes = {
  containerWithMargin: `${PREFIX}-containerWithMargin`,
  container: `${PREFIX}-container`,
  content: `${PREFIX}-content`,
  header: `${PREFIX}-header`,
  organizationHeaderContainer: `${PREFIX}-organizationHeaderContainer`,
  organizationName: `${PREFIX}-organizationName`,
  leftContainer: `${PREFIX}-leftContainer`,
  rightContainer: `${PREFIX}-rightContainer`,
};

export const StyledGrid = memo(
  styled(Grid)(({ theme }: { theme: Theme }) => ({
    [`&& .${classes.containerWithMargin}`]: {
      marginTop: theme.spacing(1),
    },

    [`&& .${classes.container}`]: {
      minHeight: () => `calc(100vh - ${HeaderHeight}px)`,
    },

    [`&& .${classes.content}`]: {
      width: "100%",
    },

    [`&& .${classes.header}`]: {
      height: theme.constants.formHeaderHeight,
      zIndex: 11,
      position: "relative",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        boxShadow: theme.boxShadow.base.light,
        paddingLeft: theme.spacing(12),
        paddingRight: theme.spacing(12),
      },
      [theme.breakpoints.down("md")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },

    [`&& .${classes.organizationHeaderContainer}`]: {
      height: "100%",
    },

    [`&& .${classes.organizationName}`]: {
      marginLeft: theme.spacing(1),
    },

    [`&& .${classes.leftContainer}`]: {
      paddingTop: theme.spacing(4),
    },

    [`&& .${classes.rightContainer}`]: {
      paddingTop: theme.spacing(4),
    },
  }))
);
