import * as React from "react";

import { Link, LinkProps } from "../design-system/Link";

type NewTabLinkProps = LinkProps & { href: NonNullable<LinkProps["href"]> | undefined; stopClickPropagation?: boolean };

export const NewTabLink: React.FunctionComponent<NewTabLinkProps> = ({
  target = "_blank",
  onClick,
  stopClickPropagation = true,
  ...rest
}) => {
  const handleOnClick: LinkProps["onClick"] = React.useCallback(
    (event: React.KeyboardEvent<HTMLAnchorElement> | React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (stopClickPropagation) {
        event.stopPropagation();
      }
      onClick?.(event);
    },
    [stopClickPropagation, onClick]
  );

  return <Link {...rest} target={target} onClick={handleOnClick} />;
};
