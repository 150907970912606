import { ThemeProvider } from "@mui/material/styles";
import { useRouter } from "next/router";

import { FormType } from "../../gql/gql-types";
import { zeffyTheme } from "../../theme/theme";
import { FormSkeleton } from "../FormSkeleton/FormSkeleton";

import { ContainerLoader } from "./ContainerLoader";

export const PageLoader = () => {
  const router = useRouter();

  if (router.pathname.includes("/ticketing/")) {
    return (
      <ThemeProvider theme={zeffyTheme}>
        <FormSkeleton formType={FormType.Ticketing} />
      </ThemeProvider>
    );
  }

  if (
    router.pathname.includes("/donation-form/") ||
    router.pathname.includes("/team/") ||
    (router.pathname.includes("/fundraising/") && !router.pathname.includes("/o/fundraising/")) || // new dashboard fundraising table must not have form loader
    router.pathname.includes("/peer-to-peer/")
  ) {
    return (
      <ThemeProvider theme={zeffyTheme}>
        <FormSkeleton formType={FormType.DonationForm} />
      </ThemeProvider>
    );
  }

  return <ContainerLoader fullPage />;
};
