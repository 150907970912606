import * as React from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { useCurrentUserContext } from "../../contexts/CurrentUserContext";
import { useLocaleContext } from "../../contexts/LocaleContext";
import { DefaultOrganizationRoute } from "../../helpers/auth";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useTranslate } from "../../hooks/useTranslate";
import { LandingPageUrl } from "../../routes/routes";
import Image from "../Image/Image";
import { ZeffyLogoRedirect } from "../ZeffyLogoRedirect/ZeffyLogoRedirect";

import { useStyles } from "./useStyles";

import { Button } from "@/design-system/Button";

type NotFoundPageProps = {
  deletedForm?: boolean;
};

const NotFoundPage: React.FC<NotFoundPageProps> = ({ deletedForm }) => {
  const { locale } = useLocaleContext();
  const { currentUser } = useCurrentUserContext();
  const classes = useStyles();
  const { t } = useTranslate();
  const { isSmallScreen } = useMediaQuery();
  const redirectionLink = React.useMemo(() => {
    if (currentUser) {
      return DefaultOrganizationRoute;
    } else {
      return LandingPageUrl[locale];
    }
  }, [currentUser, locale]);

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <ZeffyLogoRedirect src="/images/Zeffy-Logo-Color.svg" disabled />
      <Container maxWidth="sm" className={classes.container}>
        <Grid container alignItems="center" className={classes.textContainer}>
          <Grid item xs={12}>
            <Image
              src="/images/light-bulb.svg"
              alt="Light bulb"
              width={isSmallScreen ? 275 : 550}
              height={isSmallScreen ? 110 : 220}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.oops}>
              {t("dashboard", "notFound.Oops")}
            </Typography>
          </Grid>
          {deletedForm ? (
            <>
              <Grid item xs={12}>
                <Typography variant="h5" color="primary">
                  {t("dashboard", "gone.deletedForm")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">{t("dashboard", "gone.contactNonProfit")}</Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography variant="h5" color="primary">
                  {t("dashboard", "notFound.doYouHaveTheRightLink")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {t("dashboard", "notFound.writeUsTo1")}
                  <Link href="mailto:contact@zeffy.com" className={classes.link} underline="hover">
                    contact@zeffy.com
                  </Link>
                  {t("dashboard", "notFound.writeUsTo2")}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.button}>
                <Button variant={currentUser ? "filled" : "outlined"} vibe="brand" href={redirectionLink}>
                  {t("dashboard", currentUser ? "notFound.returnToDashboard" : "notFound.returnToLanding")}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default NotFoundPage;
