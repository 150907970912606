import { FC, useMemo, useRef, useContext } from "react";

import Container from "@mui/material/Container";
import useTheme from "@mui/styles/useTheme";

import { PageLoader } from "../../components/Loaders/PageLoader";
import { PageLoadingContext } from "../../providers/PageLoadingProvider";
import { ScrollProvider } from "../../providers/ScrollProvider";

import { TunnelLayoutProps } from "./types";

export const TunnelLayout: FC<TunnelLayoutProps> = ({
  children,
  noScreenPadding = false,
  disableFullHeightOnMobile = false,
}) => {
  const theme = useTheme();
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const rootStyle = useMemo(
    () => ({
      backgroundColor: theme.palette.common.white,
      height: `100vh`,
      ...(disableFullHeightOnMobile ? { [theme.breakpoints.down("md")]: { height: "unset" } } : undefined),
    }),
    [disableFullHeightOnMobile, theme.breakpoints, theme.palette.common.white]
  );

  const containerStyle = useMemo(
    () => ({
      height: "100%",
      padding: noScreenPadding ? 0 : undefined,
    }),
    [noScreenPadding]
  );

  const { isLoading } = useContext(PageLoadingContext);
  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <div style={rootStyle} ref={scrollableContainerRef}>
        <ScrollProvider scrollableContainerRef={scrollableContainerRef}>
          {children && (
            <Container maxWidth={noScreenPadding ? false : "lg"} style={containerStyle}>
              {children}
            </Container>
          )}
        </ScrollProvider>
      </div>
    </>
  );
};
