import { useCurrentUserContext } from "../contexts/CurrentUserContext";
import { View } from "../enums/view";
import { OrganizationRoutes } from "../routes/routes";

import { useAppSelector } from "./redux";

export const defaultRedirectionRoute = OrganizationRoutes.Settings;
export const NO_DASHBOARD_REDIRECTION_QUERY_PARAM = "noDashboardRedirection";

export const useRedirection = () => {
  const { userIsLoading, isAuthenticated } = useCurrentUserContext();

  const currentView = useAppSelector((state) => state.view.view);

  const {
    hasDonationForm,
    hasTicketing,
    loading: applicationInitialStatusIsLoading,
  } = useAppSelector((state) => state.applicationInitialStatus);

  const hasForm = hasDonationForm || hasTicketing;

  const isLoading = userIsLoading || applicationInitialStatusIsLoading;

  const currentViewLoading = !currentView;

  return {
    hasForm,
    isLoading,
    shouldBeInDonorDashboard: currentView === View.Donor,
    shouldBeInOrganizationDashboard: currentView === View.Organization,
    shouldBeLoggedOut: !userIsLoading && !isAuthenticated,
    currentViewLoading,
  };
};
