import { ComponentType, FC, memo } from "react";

import { useRouter } from "next/router";

import { LayoutProps } from "../../layouts/type";

import { useLogRouteChangePageVisits, RouteType } from "@/hooks/amplitude/useLogRouteChangePageVisits";

export interface PublicRouteProps extends LayoutProps {
  layout: ComponentType<LayoutProps>;
}

const PublicRouteToMemoized: FC<PublicRouteProps> = ({ layout: Layout, children, ...restOfLayoutProps }) => {
  const router = useRouter();
  useLogRouteChangePageVisits({ path: router.pathname, routeType: RouteType.PublicRoute });

  return <Layout {...restOfLayoutProps}>{children}</Layout>;
};

export const PublicRoute = memo(PublicRouteToMemoized);
