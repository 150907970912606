import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

import { StyledGrid, classes } from "./styles";

export const TicketingSkeleton = () => {
  const SelectTicketSkeleton = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Skeleton variant="rectangular" width="100%" height={50} />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" width="100%" height={15} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" width="100%" height={15} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" width="100%" height={15} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" width="80%" height={15} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Skeleton variant="rectangular" width="100%" height={50} />
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      <StyledGrid container>
        <Grid item xs={12} className={classes.header}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.organizationHeaderContainer}
          >
            <Grid item>
              <Skeleton variant="rectangular" width={50} height={50} />
            </Grid>

            <Grid item xs className={classes.organizationName}>
              <Typography variant="h4">
                <Skeleton variant="text" width="80%" height={50} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={7} className={classes.leftContainer}>
              <Container maxWidth="sm">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <SelectTicketSkeleton />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectTicketSkeleton />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectTicketSkeleton />
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton variant="rectangular" width="100%" height={40} />
                  </Grid>
                </Grid>
              </Container>
            </Grid>
            <Grid item xs={12} md={5} className={classes.rightContainer}>
              <Container maxWidth="sm">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Skeleton variant="rectangular" width="100%" height={40} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Skeleton variant="rectangular" width="100%" height={15} />
                      </Grid>
                      <Grid item xs={12}>
                        <Skeleton variant="rectangular" width="100%" height={15} />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Skeleton variant="rectangular" width="100%" height={240} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Skeleton variant="rectangular" width="100%" height={15} />
                      </Grid>
                      <Grid item xs={12}>
                        <Skeleton variant="rectangular" width="100%" height={15} />
                      </Grid>
                      <Grid item xs={12}>
                        <Skeleton variant="rectangular" width="100%" height={15} />
                      </Grid>
                      <Grid item xs={12}>
                        <Skeleton variant="rectangular" width="100%" height={15} />
                      </Grid>
                      <Grid item xs={12}>
                        <Skeleton variant="rectangular" width="100%" height={15} />
                      </Grid>
                      <Grid item xs={12}>
                        <Skeleton variant="rectangular" width="80%" height={15} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton variant="rectangular" width="100%" height={40} />
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </Grid>
      </StyledGrid>
    </>
  );
};
