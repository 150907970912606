import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { useMediaQuery } from "../../hooks/useMediaQuery";

import { classes, StyledGrid } from "./styles";

const formTitleHeight = 68;
const amountsHeight = 235;
const zeffyDescriptionHeight = 112;
const formHeight = 700;
const paymentProcessorHeight = 227;

export const DonationFormSkeleton = () => {
  const theme = useTheme();
  const { isSmallScreen } = useMediaQuery();

  const bannerHeight = amountsHeight + zeffyDescriptionHeight + theme.legacySpacing(2);
  return (
    <>
      <StyledGrid container justifyContent="center" style={{ height: "100vh" }}>
        <Grid item xs={12} className={classes.header}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.organizationHeaderContainer}
          >
            <Grid item>
              <Skeleton variant="rectangular" width={50} height={50} />
            </Grid>

            <Grid item xs className={classes.organizationName}>
              <Typography variant="h4">
                <Skeleton variant="text" width="80%" height={50} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            className={classes.container}
          >
            <Grid item xs={12} className={classes.content}>
              <Container fixed>
                <Grid container>
                  <Grid container spacing={1} className={classes.containerWithMargin}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} style={{ height: formTitleHeight }}>
                          <Typography variant="h1">
                            <Skeleton variant="text" width="100%" />
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Skeleton variant="rectangular" width="100%" height={amountsHeight} />
                            </Grid>
                            <Grid item xs={12} style={{ height: zeffyDescriptionHeight }}>
                              <Typography variant="body1">
                                <Skeleton variant="text" width="100%" />
                                <Skeleton variant="text" width="100%" />
                                <Skeleton variant="text" width="100%" />
                                <Skeleton variant="text" width="80%" />
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Skeleton variant="rectangular" width="100%" height={formHeight} />
                            </Grid>
                            <Grid item xs={12}>
                              <Skeleton variant="rectangular" width="100%" height={paymentProcessorHeight} />
                            </Grid>
                          </Grid>
                        </Grid>
                        {!isSmallScreen && (
                          <Grid item xs={12} md={6}>
                            <Grid container spacing={1} alignContent="flex-start">
                              <Grid item xs={12}>
                                <Skeleton variant="rectangular" width="100%" height={bannerHeight} />
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </Grid>
      </StyledGrid>
    </>
  );
};
