import { Translations } from "../../../public/@types/translations";
import { useTranslate } from "../../hooks/useTranslate";

import { ZeffyHead, ZeffyHeadProps } from "./ZeffyHead";

export interface DashboardHeadProps extends ZeffyHeadProps {
  description?: keyof Translations["dashboard"];
  title?: keyof Translations["dashboard"];
}

export const DashboardHead: React.FunctionComponent<DashboardHeadProps> = ({ description, title, ...rest }) => {
  const { t } = useTranslate();

  return (
    <ZeffyHead
      description={description ? t("dashboard", description) : undefined}
      title={title ? t("dashboard", title) : undefined}
      {...rest}
    >
      <meta httpEquiv="Pragma" content="no-cache" />
      <meta httpEquiv="cache-control" content="no-cache, no-store, must-revalidate" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
    </ZeffyHead>
  );
};
