import Grid from "@mui/material/Grid";
import useTheme from "@mui/styles/useTheme";
import classNames from "classnames";
import { ImageProps } from "next/image";

import { AmplitudeEvents } from "../../constants/amplitude";
import { useLocaleContext } from "../../contexts/LocaleContext";
import { useAmplitude } from "../../hooks/amplitude/useAmplitude";
import { LandingPageUrl } from "../../routes/routes";
import { Icon } from "../design-system/Icon";
import Image from "../Image/Image";
import { NewTabLink } from "../Link/NewTabLink";

import { useStyles } from "./useStyles";
import ZeffyLogo from "./zeffy_redirect.svg";

interface ZeffyLogoRedirectProps {
  disabled?: boolean;
  src?: string;
  width?: ImageProps["width"];
  height?: ImageProps["height"];
  amplitudeEvent?: { event: AmplitudeEvents; properties?: Record<string, unknown> };
  noPadding?: boolean;
  link?: string;
  color?: string;
}

export const ZeffyLogoRedirect: React.FunctionComponent<React.PropsWithChildren<ZeffyLogoRedirectProps>> = ({
  disabled,
  src,
  width = 92,
  height = 28,
  amplitudeEvent,
  noPadding = false,
  link,
  color,
}) => {
  const theme = useTheme();
  const logoColor = color || theme.palette.text.form.moderate;
  const classes = useStyles();
  const { locale } = useLocaleContext();
  const { logAmplitudeEventCallback } = useAmplitude();

  const linkUrl = link || LandingPageUrl[locale];

  return (
    <Grid
      item
      style={{
        margin: noPadding ? 0 : theme.spacing(2, 0),
        lineHeight: 0.8,
      }}
    >
      <NewTabLink
        href={disabled ? undefined : linkUrl}
        className={classNames({ [classes.clickable]: !disabled, [classes.disabled]: disabled })}
        onClick={
          amplitudeEvent ? logAmplitudeEventCallback(amplitudeEvent.event, amplitudeEvent.properties) : undefined
        }
      >
        {src ? (
          <Image alt="Zeffy logo" src={src || "/images/zeffy-logo.png"} width={width} height={height} />
        ) : (
          <Icon
            sx={{
              width: `${width}px !important`,
              height: `${height}px !important`,
              svg: {
                fill: logoColor,
              },
            }}
          >
            <ZeffyLogo />
          </Icon>
        )}
      </NewTabLink>
    </Grid>
  );
};
