import querystring from "querystring";

import { IsoLocale, UserRoles, IS_SUPER_ADMIN_QUERY_PARAM, RoutePrefix, Emptyable, Nullable } from "@simplyk/common";
import router from "next/router";

import { NO_DASHBOARD_REDIRECTION_QUERY_PARAM } from "../hooks/useRedirection";
import { DonorRoutes, OrganizationRoutes, PublicRoutes, FRONTEND_URL } from "../routes/routes";

import { View } from "@/enums/view";

export const DefaultOrganizationRoute = OrganizationRoutes.Home;
export const DefaultDonorRoute = DonorRoutes.DonorPaymentTable;

const reloadOrRedirect = (redirectionUrl: string, reload = false) => {
  if (reload) {
    window.location.href = redirectionUrl;
  } else {
    router.push(redirectionUrl);
  }
};

export const getDashboardReloadUrl = (path: string, isoLocale?: IsoLocale) =>
  `${FRONTEND_URL}${isoLocale ? "/" + isoLocale : ""}${path}`;

export const setAuthAndRedirectToApp = ({
  role,
  setView,
  redirectionUrl,
  lastUserView,
  reload = false,
  isoLocale,
}: {
  role: Emptyable<UserRoles>;
  setView?: (view: View, currentOrganizationId?: string | undefined) => void;
  redirectionUrl?: Nullable<string>;
  lastUserView?: View;
  reload?: boolean;
  isoLocale?: IsoLocale;
}) => {
  if (redirectionUrl) {
    reloadOrRedirect(redirectionUrl, reload);
  } else if (lastUserView === View.Donor || role === UserRoles.Donor) {
    setView?.(View.Donor);
    reloadOrRedirect(reload ? getDashboardReloadUrl(DefaultDonorRoute, isoLocale) : DefaultDonorRoute, reload);
  } else if (role === UserRoles.Owner || role === UserRoles.Member) {
    setView?.(View.Organization);
    reloadOrRedirect(
      reload ? getDashboardReloadUrl(DefaultOrganizationRoute, isoLocale) : DefaultOrganizationRoute,
      reload
    );
  }
};

export const isOrganizationRoute = (path?: string) => path?.includes(`${RoutePrefix.Organization}/`);

export const isPublicRoute = (path?: string) => {
  return (
    (!path?.includes(`${RoutePrefix.Donor}/`) && !path?.includes(`${RoutePrefix.Organization}/`)) ||
    // this case is to handle e-ticket QR codes before login migration
    path?.includes(`${RoutePrefix.Donor}/ticket/`)
  );
};

export const isPrivateRoute = (path?: string) => {
  return !isPublicRoute(path);
};

export const redirectOrganisationUserToLogout = ({
  connectedAsAdministrator,
}: {
  connectedAsAdministrator: boolean;
}) => {
  // * Checking if router exists for serverside rendering cases
  if (!router || !isOrganizationRoute(router.pathname)) {
    return;
  }
  const query = querystring.stringify({
    [IS_SUPER_ADMIN_QUERY_PARAM]: connectedAsAdministrator ? true : false,
  });

  return router.push(`${PublicRoutes.SignOut}?${query}`);
};

export const redirectToLogout = (options: { connectedAsAdministrator: boolean; redirectionUrl?: string }) => {
  if (!router || isPublicRoute(router.pathname)) {
    return;
  }

  const redirectionUrl = options?.redirectionUrl;
  const query = querystring.stringify({
    [IS_SUPER_ADMIN_QUERY_PARAM]: options.connectedAsAdministrator ? true : false,
    redirectionUrl,
  });

  return router.push(`${PublicRoutes.SignOut}?${query}`);
};

export const registrationLastStepUrl = `${PublicRoutes.SignUp}?${NO_DASHBOARD_REDIRECTION_QUERY_PARAM}=true#FormSignUpInformation`;
