import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(
  {
    clickable: {
      cursor: "pointer",
    },
    disabled: {
      pointerEvents: "none",
    },
  },
  { index: 10 }
);
