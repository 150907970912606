import { useContext, useEffect } from "react";

import { useRouter } from "next/router";

import { AmplitudeEvents } from "../../constants/amplitude";
import { ConsentModeContext } from "../../contexts/ConsentModeContext";

import { useAmplitude } from "./useAmplitude";
const PATH_EXCLUDED_FORM_VISIT_PAGE = ["/forms/edit", "/forms/new"];

export enum RouteType {
  "PublicRoute" = "PublicRoute",
  "OrganizationRoute" = "OrganizationRoute",
  "DonorRoute" = "DonorRoute",
}
export const useLogRouteChangePageVisits = ({
  path,
  routeType,
}: {
  path: string | readonly string[] | undefined;
  routeType: RouteType;
}) => {
  const router = useRouter();
  const { logAmplitudeEvent } = useAmplitude();
  const queryParams = router.query;
  const { hasConsentMode } = useContext(ConsentModeContext);

  useEffect(() => {
    if (PATH_EXCLUDED_FORM_VISIT_PAGE.some((subPath) => path?.includes(subPath))) {
      return;
    }
    logAmplitudeEvent(AmplitudeEvents.PageViewed, {
      path: path as string,
      routeType,
      locale: router.locale,
      ...queryParams,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, routeType, hasConsentMode]);
};
