import { useMemo } from "react";

import sanitizeHtml from "sanitize-html";

export const useHeadDescription = (input: string) =>
  useMemo(() => {
    return sanitizeHtml(input, {
      allowedTags: [],
    });
  }, [input]);
