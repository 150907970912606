import { FormType } from "@simplyk/common";

import { ZeffyLoader } from "../design-system/ZeffyLoader/ZeffyLoader";

import { DonationFormSkeleton } from "./DonationFormSkeleton";
import { TicketingSkeleton } from "./TicketingSkeleton";

interface FormSkeletonProps {
  formType: FormType;
}

export const FormSkeleton = ({ formType }: FormSkeletonProps) => {
  if (formType === FormType.DonationForm) {
    return <DonationFormSkeleton />;
  }

  if (formType === FormType.Ticketing) {
    return <TicketingSkeleton />;
  }

  return <ZeffyLoader />;
};
