import { FunctionComponent } from "react";

import { ScrollContext } from "../contexts/ScrollContext";

interface ScrollProviderProps {
  scrollableContainerRef: React.RefObject<HTMLDivElement | null>;
}

export const ScrollProvider: FunctionComponent<React.PropsWithChildren<ScrollProviderProps>> = ({
  children,
  scrollableContainerRef,
}) => {
  return <ScrollContext.Provider value={{ scrollableContainerRef }}>{children}</ScrollContext.Provider>;
};
