import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(
  (theme) => ({
    container: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    textContainer: {
      textAlign: "center",
    },
    button: {
      marginTop: theme.spacing(2),
    },
    oops: {
      color: theme.palette.warning.main,
      marginBottom: theme.spacing(2),
    },
    link: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  }),
  { index: 10 }
);
