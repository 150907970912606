import { FC, ReactNode } from "react";

import { IsoLocale, getLocalesFromIsoLocale } from "@simplyk/common";
import NextHead from "next/head";
import { useRouter } from "next/router";

import { isProduction } from "../../constants/env";
import { favicon } from "../../constants/favicon";
import { useHeadDescription } from "../../hooks/useHeadDescription";
import { palette } from "../../theme/palette";

export interface ZeffyHeadProps {
  description?: string;
  disableMobileAutoZoom?: boolean;
  image?: string;
  imageWidth?: string;
  themeColor?: string;
  title?: string;
  url?: string;
  video?: string;
  children?: ReactNode;
}

export const ZeffyHead: FC<ZeffyHeadProps> = ({
  description: unparsedDescription = "",
  disableMobileAutoZoom = true,
  image,
  imageWidth = "600px",
  themeColor = palette.primary.main,
  title: inputTitle = "Zeffy",
  url: overridenUrl,
  video,
  children,
}) => {
  // Robust Fallback in case an falsy value is passed as a title
  const currentTitle = inputTitle ? inputTitle : "Zeffy";

  const router = useRouter();
  const { locale: isoLocale } = router as { locale: IsoLocale };

  const language = getLocalesFromIsoLocale(isoLocale);

  const frontendUrl = process.env.NEXT_PUBLIC_FRONTEND_URL as string;
  const url = overridenUrl ? overridenUrl : `${frontendUrl}/${isoLocale}${router.asPath}`;

  const parsedDescription = useHeadDescription(unparsedDescription);
  const description = parsedDescription ? parsedDescription : currentTitle;

  const viewPortContent = disableMobileAutoZoom
    ? "width=device-width, initial-scale=1, maximum-scale=1"
    : "width=device-width, initial-scale=1";

  return (
    <NextHead>
      {!isProduction && <meta name="robots" content="noindex, nofollow" />}
      <title>{currentTitle}</title>
      <link rel="icon" href={favicon} />
      <link rel="manifest" href="/manifest.json" />

      <meta charSet="utf-8" />

      <meta name="author" content="Zeffy" />
      <meta name="copyright" content="Zeffy" />
      <meta name="description" content={description} />
      <meta name="language" content={language} />
      <meta name="theme-color" content={themeColor} />
      <meta name="title" content={currentTitle} />
      <meta name="viewport" content={viewPortContent} />

      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="Zeffy" />
      <meta property="og:title" content={currentTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      {image && (
        <>
          <meta property="og:image" content={image} />
          <meta property="og:image:secure_url" content={image} />
          <meta property="og:image:width" content={imageWidth} />
        </>
      )}
      {video && <meta property="og:video" content={video} />}
      {children}
    </NextHead>
  );
};
